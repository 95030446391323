import React from 'react'
import Img from 'gatsby-image'
import LinkBtn from '../linkBtn/LinkBtn'
import { TranslatedLink } from '../TranslatedLink'
import './PageHeader.scss';
import PageTitle from '../pageTitle';
import { getTranslateFunction } from '../../utilities';
import { Link } from 'gatsby'

const PageHeader = (props) => {
  const { lang, subtitle, featuredPost, backgroundImage } = props
  const t = getTranslateFunction(lang)
  const wWidth = typeof window !== 'undefined' ? window.innerWidth : 0

  console.log('Page header')
  return (
    <div className="page-header">
      <section className="hp-promo margin-top">
        <div className="container">
          <div className="row hp-promo-row">
            <div className="col-md-7 hp-promo-wrapper">
              <PageTitle {...props} />
              <p>{subtitle}</p>
              <div className="hp-promo-links d-flex">
                <div className="col-auto">
                  <LinkBtn>
                    <TranslatedLink to="/en/eshop-na-mieru">{t('Homepage.Landing.OurWork')}</TranslatedLink>
                  </LinkBtn>
                </div>
                <div className="col-auto">
                  <LinkBtn color={'silver'}>
                    <TranslatedLink to="/en/our-works/featured">{t('Homepage.Landing.Contact')}</TranslatedLink>
                  </LinkBtn>
                </div>
              </div>
              <Link
                to={featuredPost.link}
                className="hp-promo-bottom d-md-flex align-content-center d-none"
              >
                <span className="hp-promo-scroll circle-animation">
                  <i className="la la-angle-down"></i>
                  <svg viewBox="0 0 44 44" className="circle-animation">
                    <circle className="back" cx="-22" cy="22" r="22" transform="rotate(-90)"></circle>
                    <circle className="outer" cx="-22" cy="22" r="22" transform="rotate(-90)"></circle>
                  </svg>
                </span>
                <span className="hp-promo-new">
                  {featuredPost.is_new ? (<span className={'badge'}>{t('Homepage.Landing.Badge')}</span>) : null}
                  <span>{featuredPost.title}</span>
                  <img src="../images/icon_arrow.svg" alt="arrow" />
                </span>
              </Link>
            </div>
            <div className="col-md-5 text-right right">
              { wWidth < 768 
                ?
                <video autoPlay={true} muted={true} loop={true} controls={false} className={'homepage-landing-background-video'}>
                  <source src={'/videos/Vid-4-1.mp4'} type="video/mp4" />
                </video>
                :
                <video autoPlay={true} muted={true} loop={true} controls={false} className={'homepage-landing-background-video'}>
                  <source src={'/videos/Vid-4-1.mp4'} type="video/mp4" />
                </video>
              }
              <Link
                to={featuredPost.link}
                className="hp-promo-bottom d-md-none align-content-center d-flex justify-content-center"
              >
                <span className="hp-promo-scroll circle-animation">
                  <i className="la la-angle-down"></i>
                  <svg viewBox="0 0 44 44" className="circle-animation">
                    <circle className="back" cx="-22" cy="22" r="22" transform="rotate(-90)"></circle>
                    <circle className="outer" cx="-22" cy="22" r="22" transform="rotate(-90)"></circle>
                  </svg>
                </span>
                <span className="hp-promo-new text-center">
                  {featuredPost.is_new ? (<span className={'badge'}>{t('Homepage.Landing.Badge')}</span>) : null}
                  <span>{featuredPost.title}</span>
                  <img src="../images/icon_arrow.svg" alt="arrow" />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
    
  )
}

PageHeader.defaultProps = {
  lang: 'sk',
  title: '',
  subtitle: '',
  featuredPost: {},
  backgroundImage: {},
  seoTitle: '',
  description: ''
}
export default PageHeader;